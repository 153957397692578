import * as React from "react"
import HorseButton from "../../../components/horse-button"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import radHorse from "./corporate-memphis-horse.svg"

import * as style from "./index.module.scss"

const CorporateMemphisHorse = () => (
  <Layout bodyClass={style.corporateMemphisHorse}>
    <Seo title="Corporate Memphis Horse" />
    <div className={style.twoStables}>
      <div>
        <img
          src={radHorse}
          alt="A corporate memphis inspired drawing of a horse in front of a website"
        />
      </div>
      <div className={style.horseText}>
        <h1>Corporate Memphis Horse</h1>
        <p>
          This non-threatening friend is extremely rounded and can't wait to
          interact with your UI. Users see themselves in Corporate Memphis
          Horse. Does he have a big blob behind him? You betcha he does.
        </p>
        <HorseButton className={style.horseButton} />
      </div>
    </div>
  </Layout>
)

export default CorporateMemphisHorse
